import classes from './PhotoShootPage.module.css';
import ducks from './photoShootDucks';
import DogIcon from '../../assets/DogIcon.png';
import DuckIcon from '../../assets/DuckIcon.png';

const createDuck= (duck) => {
  return <DuckCard key={duck.id} content={duck.content} />;
}

const DuckCard = (props) => {
  
  return (
    <div className={classes.cards}>
      <input id={props.key}  type='checkbox' value></input>
      <h6>{props.content}</h6>
      <img src={DuckIcon} alt="logo" />
    </div>
  );
}

const PhotoShootPage = () => {
  return (
    <div className={classes.huntPage}>
      <header className={classes.header}>
        
          <img src={DogIcon} alt='dog' />
        
        <h2>Party Hunt Nash</h2>
      </header>
      <section className={classes.fullForm}>{ducks.map(createDuck)}</section>
    </div>
  );
};

export default PhotoShootPage;