import React from 'react';
import { Link } from 'react-router-dom';
import classes from './FooterLite.module.css';

const FooterLite = () => {
  return (
    <div className={classes.footer}>
      {/* <h1>🇺🇸 VETERAN OWNED 🇺🇸</h1> */}
      <div className={classes.footerContent}>
        <div>
          <ul>
            <li>
              <Link to='/'>
                CLICK HERE TO SCAVENGER HUNT AT A BAR ANYWHERE!
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FooterLite;
