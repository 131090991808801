import Footer from '../components/Footer/Footer';
import MainNavigation from '../components/MainNavigation/MainNavigation';
import classes from './ContactPage.module.css';
import { FiMail, FiInstagram } from 'react-icons/fi';

const ContactPage = () => {
  return (
    <div className={classes.contactPage}>
      <MainNavigation />
      <div className={classes.container}>
        <div className={classes.contact}>
          <h2>Contact Us</h2>
          <div className={classes.contactList}>
            <ul>
              <li>
                <a
                  href='mailto:partyhuntnash@gmail.com?subject=Party Hunt Nash'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FiMail /> partyhuntnash@gmail.com
                </a>
              </li>
              <li>
                <a
                  href='https://www.instagram.com/partyhuntnash/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FiInstagram /> PartyHuntNash
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactPage;
