import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { HuntProvider } from './store/hunt-context';

ReactDOM.render(
  <HuntProvider>
    <App />
  </HuntProvider>,
  document.getElementById('root')
);
