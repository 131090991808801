const ducks = [
  { id: 1, content: "WIN A GAME OF ROCK, PAPER, SCISSORS WITH SOMEONE ON THE STREET" },
  { id: 2, content: "STRANGER DRAWS A PEN/MARKER TATTOO ON YOU" },
  { id: 2, content: "SWING THE RING ONTO THE HOOK" },
  { id: 3, content: "STRANGER GIVES YOU A BITE OF THEIR FOOD" },
  { id: 4, content: "STRANGER HOLDS YOUR HAND WHILE CROSSING THE STREET" },
  { id: 5, content: "TIP THE BAND/DJ"},
  { id: 6, content: "POST A GROUP PICTURE AND TAG @PARTYHUNTNASH" },
  { id: 7, content: "WIN A BEER/DRINK CHUG BATTLE" },
  { id: 8, content: "HIGH-FIVE A STRANGER" },
  { id: 9, content: "GIVE A TOAST WITH STRANGERS" },
  { id: 10, content: "TAKE A PICTURE WITH A BRIDE-TO-BE" },
  { id: 11, content: "GIVE OR RECEIVE A PIGGY-BACK RIDE WITH A STRANGER INTO A BAR" },
  { id: 12, content: "WIN A BEER/DRINK CHUG BATTLE" },
  { id: 13, content: "TAKE A PICTURE WITH SOMEONE CELEBRATING THEIR BIRTHDAY" },
];
export default ducks;