import classes from './HomePage.module.css';
import React, { useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DogIcon from '../assets/DogIcon.png';
import { HuntContext } from '../store/hunt-context';
import MainNavigation from '../components/MainNavigation/MainNavigation';
import Footer from '../components/Footer/Footer';

const HuntAtHomePage = (props) => {
  const navigate = useNavigate();
  const occasionInputRef = useRef();

  const title = useContext(HuntContext);

  const occasionHandler = (event) => {
    event.preventDefault();

    navigate('/alert');
  };

  return (
    <div className={classes.huntAtHomePage}>
      <MainNavigation />
      <div className={classes.container}>
        <section className={classes.starting}>
          <img
            className={classes.mainLogo}
            src={DogIcon}
            alt='Dog logo with beers'
          />
          <form onSubmit={occasionHandler} className={classes.formOccasion}>
            <label htmlFor='occasion' className={classes.labelOccasion}>
              What's the occasion?
            </label>

            <input
              id='occasion'
              type='text'
              className={classes.inputOccasion}
              required
              ref={occasionInputRef}
              onChange={(event) => title.setHuntTitle(event.target.value)}
              placeholder='Birthday,  Friday,  ArborDay'
            />
            <div className={classes.checkbox}>
              <input type='checkbox' required></input>
              <a
                href='https://github.com/g-i-coding/Drunk_Hunt/blob/main/Terms%20of%20Use.pdf'
                target='_blank'
                rel='noreferrer'
              >
                Terms of Use
              </a>
            </div>

            <button type='submit' className={classes.buttonOccasion}>
              PLAY
            </button>
          </form>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default HuntAtHomePage;
