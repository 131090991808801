const ducks = [
  { id: 1, content: "WIN A GAME OF ROCK, PAPER, SCISSORS WITH SOMEONE ON THE STREET" },
  { id: 2, content: "POST A PICTURE WITH TEXENNESSEE AND TAG @DOGWOODNASHVILLE" },
  { id: 3, content: "STRANGER DRAWS A PEN/MARKER TATTOO ON YOU" },
  
  { id: 5, content: "PARTICIPATE IN A 'USA' CHANT" },
  { id: 6, content: "POST A PICTURE WITH 'THE DUDE' AND TAG @SLIDERHOUSENSH" },
  { id: 7, content: "STRANGER GIVES YOU A BITE OF THEIR FOOD" },
  { id: 4, content: "STRANGER HOLDS YOUR HAND WHILE CROSSING THE STREET" },
  { id: 8, content: "TIP THE BAND/DJ"},
  { id: 9, content: "POST A VIDEO OF YOU SINKING A PUT AND TAG @LIVE_OAK_NASHVILLE" },
  { id: 10, content: "STRANGER TELLS YOU THEIR FAVORITE JOKE" },
  { id: 11, content: "POST A GROUP PICTURE AND TAG @PARTYHUNTNASH" },

];
export default ducks;
