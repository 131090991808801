import React from 'react';
import { Link } from 'react-router-dom';
import classes from './Footer.module.css';
import { FiMail, FiInstagram } from 'react-icons/fi';
const Footer = () => {
  return (
    <div className={classes.footer}>
      <h1>🇺🇸 VETERAN OWNED 🇺🇸</h1>
      <div className={classes.footerContent}>
        <div className={classes.contact}>
          <ul>
            <li>
              <a
                href='mailto:partyhuntnash@gmail.com?subject=Drunk Hunt'
                target='_blank'
                rel='noreferrer'
              >
                <FiMail /> Email
              </a>
            </li>
            <li>
              <a
                href='https://www.instagram.com/partyhuntnash/'
                target='_blank'
                rel='noreferrer'
              >
                <FiInstagram /> Instagram
              </a>
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li>
              <Link to='/'>Home</Link>
            </li>
            <li>
              <Link to='/contact'>Contact Us</Link>
            </li>
            <li>
              <Link to='/partners'>Partners</Link>
            </li>
            {/* <li>
              <Link to='/About'>About</Link>
            </li> */}
            {/* <li>
            <Link to='/faq'>FAQ</Link>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
