import classes from './AlertPage.module.css';
import MainNavigation from '../components/MainNavigation/MainNavigation';
import React from 'react';
import tn from '../assets/Tennessee.png';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
const AlertPage = () => {
  return (
    <div className={classes.alertPage}>
      <MainNavigation />
      <div className={classes.container}>
        <section className={classes.alert}>
          <h1>ONLY RULES</h1>
          <img src={tn} alt='tennessee' />
          <div>
            <p>Don't break the law.</p>
            <p>Don't be a jerk.</p>
          </div>

          <Link to='/hunt'>
            <button>SEND IT</button>
          </Link>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default AlertPage;
