const ducks = [
  { id: 1, content: 'STRANGER BUYS A BEER FOR YOU' },
  { id: 2, content: 'BUY A DRINK FOR A STRANGER' },
  { id: 3, content: 'STRANGER TELLS YOU THEIR FAVORITE JOKE' },
  { id: 4, content: 'KISS FROM THE MOST ATTRACTIVE STRANGER' },
  { id: 5, content: "GET AN ATTRACTIVE STRANGER'S PHONE NUMBER" },
  { id: 6, content: 'GIVE YOUR PHONE NUMBER TO SOMEONE YOU JUST MET' },
  { id: 7, content: 'BODY SHOT OFF OF A STRANGER' },
  {
    id: 8,
    content:
      'IG POST A NEW PIC TAKEN WITH A STRANGER, TAG @PARTYHUNTNASH AND USE #DRUNKHUNT',
  },
  { id: 9, content: 'USE YOUR CHEESIEST PICK-UP LINE ON A STRANGER' },
  { id: 10, content: 'STRANGER TELLS YOU AN EMBARRASSING STORY' },
  { id: 11, content: 'SERENADE A STRANGER' },
  { id: 12, content: 'GIVE A COMPLIMENT TO A STRANGER' },
  { id: 13, content: 'STRANGER DRAWS A PEN/MARKER TATTOO ON YOU' },
  { id: 14, content: 'STRANGER GIVES YOU A BITE OF THEIR FOOD' },
  { id: 15, content: 'STRANGER DOES A BODY SHOT OFF OF YOU' },
  { id: 16, content: 'MAKE UP A POEM AND TELL A STRANGER THE POEM' },
  { id: 17, content: 'GIVE A TOAST FOR SOMEONE CELEBRATING THEIR BIRTHDAY' },
  { id: 18, content: 'STRANGER USES THEIR BEST PICK-UP LINE ON YOU' },
  { id: 19, content: 'MAKE A PERSONAL/SECRET HANDSHAKE WITH A STRANGER' },
  { id: 20, content: 'STRANGER SHOWS YOU A PICTURE OF THEIR DOG' },
  { id: 21, content: 'KISS FROM A BARTENDER' },
];
export default ducks;
