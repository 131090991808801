import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import MainNavigation from '../components/MainNavigation/MainNavigation';
import classes from './AboutPage.module.css';
import theWalk from '../assets/theWalk.png';

const HomePage = () => {
  return (
    <div className={classes.homePage}>
      <MainNavigation />

      <div className={classes.heroHeader}>
        <div className={classes.tagBox}>
          <h2>Party</h2>
          <h2>Hunt</h2>
          <h2>Drink</h2>
        </div>
        {/* <a href='https://fareharbor.com/embeds/book/partyhuntnash/?full-items=yes'>
          <div className={classes.bookButton}>
            <h3>Book Now</h3>
          </div>
        </a> */}
      </div>

      <div className={classes.bodySection}>
        <div className={classes.imgWrapper}>
          <img src={theWalk} alt='good looking men walkign down the street' />
        </div>

        <div className={classes.bookSection}>
          <h2>HUNT YOUR HOME BAR</h2>
          <p>
            Go on an adventure with your friends! All you have to do is click
            the button below or the "H.Y.H.B" link, type in your reason for YOUR
            scavenger hunt at any bar. Then see how many of the scavenger hunt
            items you can check off!
          </p>
          <span>Ages 21 and over!</span>

          <div className={classes.bookingBottom}>
            <Link to='/huntathome'>
              <div className={classes.bookButton}>
                <h3>Play Now</h3>
              </div>
            </Link>
          </div>
        </div>
        {/* <div className={classes.bookSection}>
          <h2>PARTY HUNT MIDTOWN</h2>
          <p>
            Take a tour with Party Hunt Nash and see the best bars off of
            Broadway. Enjoy our one of a kind scavenger hunt as you bar-hop from
            Midtown to Music Row.
          </p>
          <span>Ages 21 and over!</span>
          <span>Tours Saturdays and Sundays!</span>
          <span>There will be prizes for the winners!</span>
          <div className={classes.bookingBottom}>
            <a href='https://fareharbor.com/embeds/book/partyhuntnash/?full-items=yes'>
              <div className={classes.bookButton}>
                <h3>Book Now</h3>
              </div>
            </a>
            <h2>From $39</h2>
          </div>
        </div> */}
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
