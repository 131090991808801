import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AlertPage from './pages/AlertPage';
import HuntPage from './pages/HuntPage/HuntPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import PartnersPage from './pages/PartnersPage';
import ScavengerPage from './pages/ScavengerHunt/ScavengerPage';
import PhotoShootPage from './pages/PhotoShoot/PhotoShootPage';
import NashPartyBargeHunt from './pages/partnerPages/NashPartyBarge/NashPartyBarge';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/contact' element={<ContactPage />} />

        <Route path='/PHN09oct2021' element={<ScavengerPage />} exact />

        <Route path='/photoshoot' element={<PhotoShootPage />} exact />

        <Route path='/alert' element={<AlertPage />} exact />

        <Route path='/hunt' element={<HuntPage />} exact />

        <Route
          path='/partners/nashpartybarge'
          element={<NashPartyBargeHunt />}
          exact
        />

        <Route path='/partners' element={<PartnersPage />} exact />

        <Route path='/about' element={<AboutPage />} exact />

        <Route path='/' element={<HomePage />} exact />
      </Routes>
    </Router>
  );
}

export default App;
