import React, { createContext, useState } from "react";

export const HuntContext = createContext();

// This context provider is passed to any component requiring the context
export const HuntProvider = ({ children }) => {
  const [huntTitle, setHuntTitle] = useState("");

  return (
    <HuntContext.Provider
      value={{
        huntTitle,

        setHuntTitle,
      }}
    >
      {children}
    </HuntContext.Provider>
  );
};