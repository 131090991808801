import classes from './PartnersPage.module.css';
// import { Link } from 'react-router-dom';
import Tennessee from '../assets/Tennessee.png';
import MainNavigation from '../components/MainNavigation/MainNavigation';
import Footer from '../components/Footer/Footer';
import { Link } from 'react-router-dom';

const PartnersPage = () => {
  return (
    <div className={classes.partnersPage}>
      <MainNavigation />
      <div className={classes.container}>
        <div className={classes.partnersBox}>
          <h1>P.H.N Partners</h1>

          <img src={Tennessee} alt=' a partners party barge' />

          <ul>
            <li className={classes.tourMain}>
              <Link to='/partners/nashpartybarge'>Nashville Party Barge</Link>
            </li>
            <li className={classes.tourWebsite}>
              <a
                href='https://www.nashvillepartybarge.com/party-tours/tipsy-scavenger-hunt/'
                target='_blank'
                rel='noreferrer'
              >
                Book a Tour
              </a>
            </li>
            <div className={classes.tourWebsiteBox}></div>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PartnersPage;
