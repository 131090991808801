import classes from './HuntPage.module.css';
import DogIcon from '../../assets/DogIcon.png';
import React, { useContext } from 'react';
import { HuntContext } from '../../store/hunt-context';
import { Link } from 'react-router-dom';
import ducks from './default-ducks';
import DuckIcon from '../../assets/DuckIcon.png';
import Footer from '../../components/Footer/Footer';
const createDuck = (duck) => {
  return <DuckCard key={duck.id} content={duck.content} />;
};

const DuckCard = (props) => {
  return (
    <div className={classes.cards}>
      <input type='checkbox'></input>
      <h6>{props.content}</h6>
      <img src={DuckIcon} alt='logo' />
    </div>
  );
};

const HuntPage = () => {
  const title = useContext(HuntContext);

  return (
    <div className={classes.huntPage}>
      <header className={classes.header}>
        <Link to='/'>
          <img src={DogIcon} alt='dog' />
        </Link>
        <h2>{title.huntTitle} Scavenger Hunt</h2>
      </header>
      <section className={classes.fullForm}>{ducks.map(createDuck)}</section>
      <Footer />
    </div>
  );
};

export default HuntPage;
