import { Link } from 'react-router-dom';
import MainLogo from '../../assets/DogIcon.png';
import classes from './MainNavigation.module.css';
// import { FiMenu } from 'react-icons/fi';

const MainNavigation = () => {
  return (
    <header className={classes.header}>
      <div className={classes.container}>
        <Link to='/'>
          <div className={classes.logo}>
            <img src={MainLogo} alt='dog logo'></img>
          </div>
        </Link>

        <h3>PARTY HUNT NASH</h3>
        {/* <button>
        <FiMenu />
      </button> */}
        <div className={classes.navLinks}>
          <ul>
            <li>
              <Link to='/'>Home</Link>
            </li>
            <li>
              <Link to='/contact'>Contact</Link>
            </li>
            <li>
              <Link to='/partners'>Partners</Link>
            </li>
            {/* <li>
              <Link to='/about'>About</Link>
            </li> */}
          </ul>
        </div>
      </div>
    </header>
  );
};

export default MainNavigation;
