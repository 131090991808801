const ducks = [
  { id: 1, content: 'GET A PIGGY-BACK RIDE FROM A STRANGER' },
  { id: 2, content: 'HAVE SOMEONE SERENADE YOU FROM A ROOFTOP' },
  { id: 3, content: 'ASK A MARRIED COUPLE FOR LIFE ADVICE' },
  { id: 4, content: 'CONVINCE SOMEONE TO MAKE AN ARMPIT FART' },
  { id: 5, content: "GET A BARTENDER'S PHONE NUMBER" },
  {
    id: 6,
    content:
      'POST A GROUP PIC HOLDING THE GROOM OR BRIDE TO-BE TO INSTAGRAM, TAG @NASHPARTYBARGE',
  },
  { id: 7, content: "TAKE A STRANGER'S HAND AND TELL THEM THEIR FORTUNE" },
  {
    id: 8,
    content:
      'GIVE A TOAST, FOR ALL TO HEAR, TO THE BRIDE OR GROOM TO-BE AT THE CORNER OF BROADWAY',
  },
  {
    id: 9,
    content: 'FIND THE GIANT RING, TAKE A PHOTO, AND TAG @NASHPARTYBARGE',
  },
  { id: 10, content: 'START OR PARTICIPATE IN A CONGA LINE' },
  {
    id: 11,
    content:
      'TAKE A PICTURE WITH SOMEONE CELEBRATING THEIR BIRTHDAY, POST TO IG AND TAG @PARTYHUNTNASH',
  },
  {
    id: 12,
    content: 'WIN ROCK, PAPER, SCISSORS WITH A STRANGER ON THE STREET',
  },
  { id: 13, content: 'WIN A BEER/DRINK CHUG BATTLE' },
  { id: 14, content: 'HIGH-FIVE A STRANGER' },
  { id: 15, content: 'GIVE A TOAST WITH STRANGERS' },
  { id: 16, content: 'TAKE A PICTURE WITH A GROOM-TO-BE' },
  {
    id: 17,
    content: "FIND THE BOOT MURAL AT LOSER'S(DOWNTOWN), SNAP A PICTURE WITH IT",
  },
  { id: 18, content: "PHOTOBOMB A 'STRANGER'S' PHOTO" },
  { id: 19, content: "GRIND ON THE 'KING OF ROCK'N'ROLL" },
  {
    id: 20,
    content: 'FIND THE WORLD FAMOUS ORCHID LOUNGE, SNAP A PICTURE WITH IT',
  },
  { id: 20, content: 'FIND ME - I AM A $400K CAR HANGING ON THE WALL' },
  { id: 21, content: 'ASK DIRECTIONS TO THE NEAREST ADULT STORE' },
  {
    id: 22,
    content: 'FIND AND TAKE PHOTO OF A DOLLAR BILL SIGNED AND ON THE WALL',
  },
  {
    id: 23,
    content:
      'BOOT, SCOOT, AND BOOGIE TO THE LARGEST BOOT ON BROADWAY AND YELL "YEE-HAW"',
  },
  {
    id: 24,
    content:
      "KNOWN FOR IT'S MOONSHINE, AND ONLY BAR IN NASHVILLE WITH A DEER MOUNTED- FIND AND TAKE A PHOTO WITH THE DEER",
  },
  {
    id: 25,
    content: 'TAKE A PHOTO OF THE CHARLIES ANGEL POSE WITH A STRANGER',
  },
  { id: 26, content: 'FIND HOW MANY COLORFUL POLES IN THE CIRCLE' },
  {
    id: 27,
    content:
      "FIND THE HEROE'S BAR AND TAKE A VIDEO SINGING 'SAVE A HORSE, RIDE A COWBOY",
  },
  { id: 28, content: 'TAKE A PICTURE OF THE FLAG OF TENNESSEE' },
  {
    id: 29,
    content:
      'PICK A COUNTRY MUSIC SINGERS BAR ON BROADWAY AND ASK A STRANGER TO ASSIST YOU IN RECITING AT LEAST 6 SECONDS OF ONE OF THEIR SONGS(TAKE VIDEO)',
  },
  {
    id: 30,
    content:
      'TAKE A GROUP PHOTO WITH 2 STRANGERS THAT REMIND YOU OF YOUR GRANDPARENTS',
  },
  {
    id: 31,
    content:
      'THATS MY KIND OF NIGHT! GIVE YOUR BEST IMPERSONATION BELOW THE BLACK CHEVY TRUCK(TAKE VIDEO)',
  },
  {
    id: 32,
    content: 'TAKE THE BULL BY THE HORNS AT UNDERGROUND',
  },
  {
    id: 33,
    content:
      'FIND THE TRUE AMERICAN BAR THAT HONORS THE MILITARY AND POLICE AND THANK THEM',
  },
  {
    id: 34,
    content:
      "IF YOU'RE NOT AT WINNERS, YOU'RE AT WHAT BAR? SNAP A PHOTO WITH THE SIGN",
  },
];
export default ducks;
