// import { Link } from 'react-router-dom';

import DogIcon from '../../../assets/DogIcon.png';
// import DuckIcon from "../../../images/DuckIcon.png";
import Anchor1 from './anchor1.png';
// import Anchor2 from "./anchor2.png";

import NashPartyBargeLogo from './NashPartyBarge.png';
import classes from './NashPartyBarge.module.css';
import ducks from './nashPartyBargeDucks';
import FooterLite from '../../../components/FooterLite/FooterLite';

function createDuck(duck) {
  return <DuckCard key={duck.id} content={duck.content} />;
}

function DuckCard(props) {
  return (
    <div className={classes.cards}>
      <input type='checkbox'></input>
      <h6>{props.content}</h6>
      <img id={classes.cardIcon} src={Anchor1} alt='anchor' />
    </div>
  );
}

const NashPartyBargeHunt = () => {
  return (
    <>
      <div>
        <header className={classes.header}>
          <img src={DogIcon} alt='dog' />

          <h2>Nashville Tipsy Scavenger Hunt</h2>

          <img
            id={classes.bizLogo}
            src={NashPartyBargeLogo}
            alt='logo of Nashville Party Barge'
          />
        </header>
      </div>
      <section className={classes.fullForm}>{ducks.map(createDuck)}</section>
      <FooterLite />
    </>
  );
};

export default NashPartyBargeHunt;
